<template>
  <div class="d-flex full-width-image">
    <form class="form-signin p-5" v-on:submit.prevent="submitUserData(), submitted = true">
      <h1 class="h3 mt-2 p-0 m-3 font-weight-normal text-center">{{$t('login_connect_to_invoxia')}}</h1>
      <label for="inputEmail" class="sr-only">{{$t('login_email')}}</label>
      <input v-model="username" type="email" id="inputEmail" class="form-control" :placeholder="$t('login_email')" required
              autofocus>
      <div v-if="advanced">
      <label for="inputAdmin" class="sr-only">{{$t('admin_email')}}</label>
      <input v-model="admin" type="email" id="inputAdmin" class="form-control" placeholder="$t('admin_email')" required>
      </div>
      <label for="inputPassword" class="sr-only">{{$t('login_password')}}</label>
      <input v-model="password" type="password" id="inputPassword" class="form-control" :placeholder="$t('login_password')"
              required>
      <button class="btn btn-lg btn-primary btn-block" type="submit" :disabled="submitted">{{$t('login_signin')}}</button>
      <div class="mt-4 mb-2 text-center">
        <a :href="password_reset_url">{{$t('login_reset_password')}}</a>
        <h5 class="bg-light text-danger" v-if="error">{{$t('login_error')}}</h5>
        <p class="mt-2 text-muted">&copy; 2017-2024</p>
      </div>
    </form>
  </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault'

import { mapActions, mapGetters, mapMutations } from "vuex";

import httpClient from '../api/httpClient';

export default {
  name: 'LoginPage',
  data () {
    return {
        'username':null,
        'password':null,
        'admin':null,
        'advanced': true,
        'error': null,
        'submitted': false,
        'password_reset_url': '/password_reset'
    }
  },

  computed: {
    user_data() {
      let login_data = { email: this.username, password: this.password };
      if (this.admin) {
        login_data.admin = this.admin;
      }
      return login_data;
    }
  },

  methods: {
    ...mapActions('users', ['fetchUser']),
    async submitUserData() {
      this.error = await this.fetchUser(this.user_data);
      this.submitted = false;
    }
  },
  
  created() {
    this.$emit('update:layout', LayoutDefault);
  },
  mounted: function(){
     let parameters = this.$route.query;
     this.advanced = parameters['advanced'];
     let _baseURL = httpClient.defaults.baseURL;
     this.password_reset_url = _baseURL + '/password_reset';
  }
}
</script>

<style scoped>
.full-width-image {
  height:100%;
  width:100%;
  
  background-image: url("/images/background-desk.jpg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>